@import '../theme-bootstrap';

// common tout styles
.tout {
  &__header {
    &--large {
      @include h1;
      line-height: 1;
    }
    &--medium {
      @include h2;
      line-height: 1;
    }
    &--small {
      @include h3;
      line-height: 1;
    }
  }
  &__copy {
    &--large {
      @include t1;
    }
    &--small {
      @include t2;
    }
  }
  &__cta {
    margin: 20px 0 0 0;
  }
  p {
    padding: 0;
    margin: 0;
    line-height: inherit;
  }
  // landscape version
  &--landscape {
    @include pie-clearfix;
    position: relative;
    width: 100%;
    .tout__content {
      padding: 20px;
    }
    &.tout--bg-image {
      .tout__content {
        position: absolute;
        top: 0;
        width: 100%;
      }
    }
  }
}
